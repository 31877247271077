export default class Directions {
    constructor() {
        this.items = [
            {
                "id": 1,
                "icon": "box-arrow-in-down-right",
                "label": "middleware.direction.in.title",
            },
            {
                "id": 2,
                "icon": "box-arrow-up-right",
                "label": "middleware.direction.out.title",
            },
        ];
    }
}