<template>
  <div class="w-fit-content">
    <template v-if="!isChild">
      <div class="middleware-card" >
        <p class="font-weight-bolder m-0 text-center text-success">START</p>
        
      </div>
      <div class="middleware-arrow-container">
        <b-icon icon="arrow-down-short" class="m-0" shift-v="-5" scale="1.3" variant="success"/>
      </div>
    </template>
        <div v-for="(mid, index) in middlewares" :key="`middleware-clipboard-${index}-${uuid}`" class=" position-relative w-fit-content" :class="isChild ? 'ml-2' : ''">

          <div v-if="getMiddlewareType(mid.type).hasChildren" class="middleware-conector-line" />

          <!-- card ========== -->
          <div class="middleware-card">
            <div class="d-flex align-items-center justify-content-start">
              <div class="middleware-icon">
                <b-icon :icon="getMiddlewareType(mid.type).icon" scale="1"/>
              </div>
              <div>
                <div class="font-weight-bolder middleware-name">{{mid.name}}</div>
                <div class="text-secondary smaller-text">{{$t(getMiddlewareType(mid.type).name)}}</div>
              </div>
            </div>
          </div>

          <div class="middleware-arrow-container" v-if="!((index == middlewares.length-1) && !isChild)">
            <b-icon icon="arrow-down-short" class="m-0" shift-v="-5" scale="1.3" variant="success"/>
          </div>
          <!-- card ========= -->

        
          <template v-if="getMiddlewareType(mid.type).hasChildren">
            <flux-clipboard-middleware-display :isChild="true" :middlewares="mid.middlewares"/>
          </template>
        
        </div>



        <template v-if="!isChild">
          <div class="middleware-arrow-container">
            <b-icon icon="arrow-down-short" class="m-0" shift-v="-5" scale="1.3" variant="success"/>
          </div>
          <div class="middleware-card" >
            <p class="font-weight-bolder m-0 text-center text-success">END</p>
          </div>

        </template>

  </div> 
  
</template>

<script>
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Middlewares from '@/custom/class/Enum/Middlewares.js'
import { v4 as uuidv4 } from 'uuid'

import {
  BButton,
  BCard,
  BTabs,
  BTab,
  BFormInput,
  BIcon,
  BCollapse,
  BModal,
  BFormFile,
  BFormTextarea
  
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BTabs,
    BTab,
    BFormInput,
    BIcon,
    BCollapse,
    BModal,
    VuePerfectScrollbar,
    BFormFile,
    BFormTextarea,
    FluxClipboardMiddlewareDisplay: () => import('@/views/pages/middleware/MiddlewareManagement/Components/FluxOverview/ScriptMiddlewaresDisplay.vue')
  },
  props: {
    middlewares:{
      type: Array,
      required: true
    },
    isChild:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uuid : uuidv4()
    }
  },
  computed: {
    ...mapGetters(["getSelectedMiddlewares"]),
  },
  methods: {
    getMiddlewareType(id){
      let mids = new Middlewares().items
      let r = mids.find(el=> el.id == id)
      return r
    },
  },
}
</script>

<style lang="scss" scoped>
*{
  --middleware-width: 200px
}
.w-fit-content{
  width: fit-content;
}
.middleware-card{
  overscroll-behavior: contain !important;
  background-color: #40485f;
  border-radius: 4px;
  margin-top: 0;
  padding: 2px 5px;
  width: var(--middleware-width);
  margin-top: 10px;
  z-index: 1;
  position: relative;
  
  .middleware-name{    
    max-width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
  .middleware-icon{
    border: 1px solid var(--secondary);
    padding: 2px 4px;
    margin-right: 5px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.05);
  }
  font-size: 12px;
  .smaller-text{
    font-size: 10px;
  }
}

.middleware-arrow-container{
  width: var(--middleware-width);
  display: flex;
  justify-content: center;
}
.middleware-conector-line{
  position: absolute;
  height: 100%;
  border-left: 1px solid var(--secondary);
  top: 20px;
  z-index: 0;
  left: 10px;
}

</style>