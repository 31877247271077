export default class ConnectionStatus {
  constructor() {
      this.items = [
          {
              "id": 1,
              "label": "Active",
              "variant": "light-success"
          },
          {
              "id": 2,
              "label": "Inactive",
              "variant": "light-danger"
          },
          {
              "id": 3,
              "label": "In Maintanance",
              "variant": "light-warning"
          },
      ];
  }
}