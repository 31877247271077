import Sources from "@/custom/class/Enum/Sources.js"

export default class ProcessSourceValues {
  constructor() {
  }

  getAllSourcePaths() {
    let sources = new Sources().items
    let result = []

    sources.forEach(src => {
      result.push(src.key)
      src.fields.forEach(field => {
        result.push(`${src.key}.${field}`)
      });
    });

    return result;
  }
}