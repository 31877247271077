export default class EnumRetryLimits {
  constructor() {
    this.items = [
      {
        id: 1,
        label: 'enum.retry.no_attempts',
        minutes: 999999999999,
        max_attempts: 0,
      },
      {
        id: 2, 
        label: 'enum.retry.one_minute', 
        minutes: 1, 
        max_attempts: 60,
      },
      {
        id: 3, 
        label: 'enum.retry.five_minutes', 
        minutes: 5, 
        max_attempts: 40,
      },
      {
        id: 4,
        label: 'enum.retry.ten_minutes', 
        minutes: 10, 
        max_attempts: 24,
      },
      {
        id: 5,
        label: 'enum.retry.twenty_minutes',
        minutes: 20,
        max_attempts: 15,
      },
      {
        id: 6,
        label: 'enum.retry.thirty_minutes',
        minutes: 30,
        max_attempts: 12,
      },
      {
        id: 7, 
        label: 'enum.retry.one_hour', 
        minutes: 60, 
        max_attempts: 12,
      },
      {
        id: 8, 
        label: 'enum.retry.six_hours', 
        minutes: 360, 
        max_attempts: 4,
      },
      {
        id: 9, 
        label: 'enum.retry.twelve_hours', 
        minutes: 720, 
        max_attempts: 4,
      },
      {
        id: 10,
        label: 'enum.retry.twenty_four_hours',
        minutes: 1440,
        max_attempts: 2,
      },
    ]
  }
}
