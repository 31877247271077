export default class Categories {
    constructor() {
        this.items = [
            {
                id: 1,
                identifier: 'free',
                label: "middleware.category.free.title",
                fetch: "getAllFreeMiddlewares",
                require: {
                    event: false,
                    service: false,
                },
            },
            {
                id: 2,
                identifier: 'service',
                label: "middleware.category.service.title",
                fetch: "getAllEventMiddlewares",
                require: {
                    event: false,
                    service: true,
                },
            },
            {
                id: 3,
                identifier: 'event',
                label: "middleware.category.event.title",
                fetch: "getAllServiceMiddlewares",
                require: {
                    event: true,
                    service: false,
                },
            },
            {
                id: 4,
                identifier: 'service_event',
                label: "middleware.category.service_event.title",
                fetch: "getAllServiceEventMiddlewares",
                require: {
                    event: true,
                    service: true,
                },
            },
            {
                id: 5,
                identifier: 'webhook',
                label: "middleware.category.webhook.title",
                fetch: "getAllWebhookMiddlewares",
            }
        ];
    }

    getFromIdentifier(identifier) {
        const obj = {
            free: 1,
            service: 2,
            event: 3,
            service_event: 4,
            webhook: 5,
        };

        return this.items[obj[identifier] - 1];
    }

    getNext(current_id) {
        let nxt = current_id;
        if(nxt >= (this.items.length - 1)) {
            nxt = 0;
        }

        return this.items[nxt];
    }

    getPrevious(current_id) {
        let prv = current_id - 2;
        if(prv < 0) {
            prv = this.items.length - 2;
        }

        return this.items[prv];
    }
}