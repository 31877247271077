<template>
  <div class="">
    <div>
      <b-button
        v-if="hasAgents"
        class="ml-75 toggle-agents-btn highlightable-middleware-action"
         :class="timelineShown ? ' down' : '' "
        variant="none"
        @click="toggleTimeline"
      >
        <b-icon icon="caret-down-fill" shift-v="-1" scale="0.9" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { 
  BButton,
  BIcon,
  BIconstack,

} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

  export default {
    components: {
      BButton,
      BIcon,
      BIconstack,
    },
    props: {
      hasAgents: {
        type: Boolean,
        default: true
      },
      canAdd: {
        type: Boolean,
        default: true
      },
      timelineShown: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        uuidMap: {},
        timelineIsShown: null
      }
    },
    computed: {
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.timelineIsShown = this.timelineShown
      },
      selectAgent(id) {
        this.$emit('selectAgent', id)
      },
      confirmDeleteMiddleware() {
        this.$emit('deleteMiddleware')
      },
      toggleTimeline() {
        this.timelineIsShown = !this.timelineIsShown;
        this.$emit('toggleAgents', !this.timelineShown)
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
    }
  }
</script>
<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.animate-opt {
  --animate-delay: 0.13s;
  --animate-duration: 0.6s;
}
.toggle-agents-btn{
  --second-color: rgba(255, 255, 0255, .4);

  transition: all 0.2s , transform 0.1s;

  --black-black: #090c14;
  border-radius: 137px;
  padding: 4px;
  border: 2px groove var(--second-color);
  color: var(--secondary);
  box-shadow: 0px 0px 0 3px var(--black-black);
  opacity: 0.7; 
  &:hover , &:focus{
    filter: brightness(1.5);
  }
  &:active{
    filter: brightness(4) !important;
    transform: scale(1.05);
  }

  &.down{
    rotate: -180deg !important;
  }
}

/*
.highlightable-middleware-action{
  color: $selection-orange;
  filter: none !important;
  box-shadow: 0 0 0 1px $selection-orange !important;
}
*/
</style>