export default class MiddlewareOrigin {
  constructor() {
    this.items = [
      {
        id: 1,
        origin: "internal",
        label: "enum.middleware.origin.internal",
        icon: "tools",
        variant: "primary",
        active: true,
      },
      {
        id: 2,
        origin: "integrator",
        label: "enum.middleware.origin.integrator",
        icon: "box-seam",
        variant: "purple",
        active: true,
        agents: {
          uri: {
            default_block: 1,
            default_action: 3,
            register_destiny: {
              source: 1,
              value: "URI",
            },
          },
          method: {
            default_block: 1,
            default_action: 3,
            register_destiny: {
              source: 1,
              value: "METHOD",
            },
          },
          path: {
            default_block: 1,
            default_action: 3,
            register_destiny: {
              source: 1,
              value: "PATH",
            },
          },
          body: {
            default_block: 1,
            default_action: 3,
            register_destiny: {
              source: 1,
              value: "BODY",
            },
          },
          headers: {
            default_block: 1,
            default_action: 3,
            register_destiny: {
              source: 1,
              value: "HEADER"
            },
          },
          queries: {
            default_block: 1,
            default_action: 3,
            register_destiny: {
              source: 1,
              value: 'QUERY'
            },
          }
        }
      }
    ]
  }
}