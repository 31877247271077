<template>
  <span class="position-relative">
    <span class="position-relative">
      <b-button 
        class="execution-order-helper" 
        variant="none" 
        @click="toggle()" 
        @blur="helperActive=false" 
        ref="toggle-helper-btn" 
        :style="`transform: scale(${buttonScale});`"
      >
        ?
    </b-button>
    </span>

  <div class="position-helper" :class="dir">
  <b-collapse :visible="helperActive" appear class="">

    <div class="position-relative px-2" align="right" v-if="dir=='left'">
      <div class="down-caret" align="center"/>
    </div>
    <div class="position-relative px-2" align="left" v-if="dir=='right'">
      <div class="down-caret" align="center"/>
    </div>

    <div>
      <div class="outer-container">
        <div align="center" class="w-100 ">
          <span class="main-label">Execution Order:</span>
        </div>

        <div class="border rounded p-50">
          <div class="" v-for="dir in ['IN', 'OUT']">
            <div class="d-flex justify-content-end align-items-center">

              <span class="mr-1">{{dir}}:</span>
              <b-badge variant="light-info">Free</b-badge>
              <b-icon icon="arrow-right-short" class="mx-25"/>
              <b-badge variant="light-warning">Event</b-badge>
              <b-icon icon="arrow-right-short" class="mx-25"/>

              <b-badge variant="light-success">Service</b-badge>
              <b-icon icon="arrow-right-short" class="mx-25"/>

              
              <span class="event-service-badge"><span class="service">Service</span> + <span class="event">Event</span></span>

              
            </div>

            <template v-if="dir=='IN'">
              <div class="d-flex justify-content-start my-50 mx-4 arrow-container">
                <b-icon icon="arrow-return-left" shift-v="1" variant="secondary" scale="0.8"/>

                <div class="direction-arrow"><hr></div>
                <b-icon icon="arrow90deg-down" shift-v="-5" variant="secondary" scale="0.8"/>

              </div>
              <!-- 
              <div align="center" class="d-flex justify-content-center py-25"> 
                <hr class="my-50 w-25 mx-1">
                <b-icon icon="diamond" scale="0.5" variant="secondary"/>
                <hr class="my-50 w-25 mx-1">
              </div> -->

            </template>

          </div>
        </div>
      </div>  
      
    </div>
  </b-collapse>
  </div>
</span>
</template>

<script>

import { 
  BCol,
  BRow,
  BIcon,
  BBadge,
  BCollapse,
  BButton
 } from 'bootstrap-vue';

export default {
  components: {
    BCol,
    BRow,
    BIcon,
    BBadge,
    BCollapse,
    BButton
  },
  props: {
    dir: {
      type: String,
      default: "left"
    },
    buttonScale:{
      type: [String, Number],
      default: 1
    }
  },
  data() {
    return {
      helperActive: false
    }
  },
  methods: {
    toggle() {
      if (this.helperActive) return

     this.helperActive = !this.helperActive

     this.$refs['toggle-helper-btn'].focus()
    }
  },
}
</script>

<style lang="scss" scoped>
*{
  --main-bg-color: var(--dark-blue); 
  z-index: 3;
}
.outer-container{
  background-color: var(--main-bg-color);
  width: fit-content !important;
  border-radius: 8px;
  padding: 10px;
  
  position: relative;
  box-shadow: 0  0 15px rgba(0, 0, 0, 0.3);
}
.main-label{
  font-weight: b;
  color: var(--secondary);
  display: block;
  font-size: 14px;
  letter-spacing: 3px;
  margin-bottom: 2px;
  line-height: 1;
}

.event-service-badge{
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  padding: 0.3rem 0.5rem;
  border-radius: 0.358rem;
  word-break: keep-all;
  white-space: nowrap ; white-space: pre;
  .service{
    color: #27c56e
  }
  .event{
    color: #eb9443;
  }
}

.arrow-container{
  direction: rtl;
  .direction-arrow{
    width: 89%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -1px -8px 0 -5px; 
    background-color: var(--main-bg-color);

    hr{
      margin: 0;
      width: 100%; 
      padding:0;
      height: z;
      border-color:#63686f;
    }

  }

}

  .down-caret {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid var(--main-bg-color);
    margin-top: 15px;
  }


.execution-order-helper{
  font-weight: bolder;
  font-size: 16px;
  border-radius: 100px;
  padding: 3px 6px;
  border: 2px solid var(--secondary);
  color: var(--secondary);
  cursor: pointer;
  transition: all 0.1s;
  &:hover{
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.3);
  }
  &:active{
    border: 2px solid var(--light);
    color: var(--light)
  }

}


.position-helper.left{
  position: absolute;
  right: -15px;
  top: 50%;
  padding-bottom: 18px;
  pointer-events: none;
}
.position-helper.right{
  position: absolute;
  left: -15px;
  top: 50%;
  padding-bottom: 18px;
  pointer-events: none;
}
</style>