export default class Variable {
  constructor({ 
    type = 'constant',
    value = '',
    index = '',
    variant = 'light-secondary',
    title = '',
    domClass = '',

  }) {
    this.type = type
    this.value = value
    this.index = index
    this.variant = variant
    this.title = title,
    this.domClass = domClass
  }
}