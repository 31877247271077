<template>
  <div>
    <b-collapse :visible="showingSearcher" @shown="$refs['flux-search-input'].focus()">

      <div align="right"  class="search-outer-container" tabindex="0"
        @keydown.down="(e) =>{ e.preventDefault(); shiftMatchedIndex(+1)}"
        @keydown.up="(e) =>{ e.preventDefault(); shiftMatchedIndex(-1)}"
      > 
        <div class="px-1">
          <div align="left" class="d-flex justify-content-between align-items-start">
            <h5 class="mt-1">Flux Search:</h5>
            <b-button class="p-50" variant="none" @click="clearSearch()">
              <b-icon icon="x" scale="1.3" variant="secondary"/>
            </b-button>
          </div>
          
          <div class="d-flex justify-content-between align-items-end">
            <div>
              <span class="small text-secondary mx-25">Ctrl + Shift + F</span>
            </div>

            <div>
              <div class="search-options-container d-flex justfy-content-end align-items-center">
                <label class="small font-weight-bold mb-0" for="case-sensitive-input">Case Sensitive</label>
                <b-form-checkbox class="ml-50" variant="warning" v-model="searchOptions.caseSensitive" id="case-sensitive-input"/>

                <label class="small font-weight-bold ml-2 mb-0" for="whole-word-input">Strong Match</label>
                <b-form-checkbox class="ml-50" v-model="searchOptions.matchWholeWord" id="whole-word-input"/>
              </div>
            </div>
          </div>

          <div class="search-container">
            <b-input-group class="" @keydown.enter="searchInFlux()" >
              <b-form-input size="sm" class="flux-search-input" autofocus placeholder="Search in Flux..." v-model="fluxSearchTerm" ref="flux-search-input" @input="clearSearch(false)"/>
              <b-input-group-append >
              
              <b-button variant="outline-light" size="sm" class="" @click="searchInFlux()">
                <b-icon icon="search" class="position-absolute" scale="0.9" shift-h="-2" shift-v="1"/>
                <span class="pl-1"></span>
              </b-button>

              </b-input-group-append>
            </b-input-group>
          </div>
          
          
          <div align="left">
            <div class="search-result-container mx-50" :class="searchResult.matches == undefined ? ' invisible' : ''"> 
              <span class="text-muted" v-if="!searchResult.matches || searchResult.matches.length == 0">No Matches Found</span>
              
              <div class="text-success w-100 d-flex" v-else>
                <div class="mr-2">
                  <b-icon icon="arrow-down-circle-fill" variant="success" scale="1.3" class="cursor-pointer" @click="shiftMatchedIndex(+1)"/>
                  <span class="mx-25 text-success index-container">
                    {{searchResult.navIndex}}
                  </span>
                  <b-icon icon="arrow-up-circle-fill" variant="success" scale="1.3" class="cursor-pointer" @click="shiftMatchedIndex(-1)"/>
                </div>
                
                <div>
                  <b-button class="p-0 " variant="none" @click="viewDetails = !viewDetails">
                    <span class="small text-success">
                      <span >{{searchResult.matches.length}}</span>
                      Use<template v-if="searchResult.matches.length > 1">s</template> found 
                      <b-icon :icon="viewDetails ? 'chevron-up':'chevron-down'" shift-v="-1" scale="0.9" class="ml-25"/>
                    </span>
                  </b-button>
                </div>
              </div>

            </div>
            <b-collapse :visible="viewDetails">
              <div class="result-details mx-75" >
                <div class="font-weight-bolder text-success">Filter Matches: </div>

                <div v-for="(value, key , idx) in matchedTypes" :key="'detail-match-'+idx" class="font-weight-bold px-25">
                  <div v-if="value > 0" class="mt-25">
                  
                    <b-form-checkbox size="sm" class="ml-2 mt-0 p-0 d-inline-block m-0" :id="'matches-checkbox-' + key" v-model="resultTypeShowing[key]" @change="updateSearchFilter()"/>
                    <label :for="'matches-checkbox-' + key" tabindex="0" :class="resultTypeShowing[key] ? '' :'low-opacity-label'" class="cursor-pointer">
                      <span class="">{{matchTypeDictionary[key]}}</span> 
                        <b-icon icon="arrow-right-short" scale="1.2" variant="success" class="mx-50"/>
                    
                      <span>
                        <span class="selection-orange font-weight-bolder">{{value}}</span> 
                        match<template v-if="value > 1">es</template>
                      </span>
                    </label>

                  </div>

                </div>
              
              </div>
            </b-collapse>
          </div>
      </div>
      </div>

    </b-collapse>  
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import anime from "animejs";

import {
  BButton,
  BCard,
  BTabs,
  BTab,
  BFormInput,
  BCollapse,
  BSpinner,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormGroup,

} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BTabs,
    BTab,
    BFormInput,
    BCollapse,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormGroup,
  },
  data() {
    return {
      showingSearcher:false,
      fluxSearchTerm: '',
      searchOptions:{
        matchWholeWord: false,
        caseSensitive: false
      },
      searchResult: {
        matches: undefined,
        navIndex: 1,
        paramsUsed: undefined
      },
      viewDetails: false,
      
      matchTypeDictionary:{
        'agentSource': 'Source',
        'agentValue': 'Input Value',
        'agentType': 'Agent Type',
        'middlewareType': 'Middleware Type',
        'middlewareName': 'Middleware Name',
      },
      resultTypeShowing:{
        'agentSource': true,
        'agentValue': true,
        'agentType': true,
        'middlewareName': true,
        'middlewareType': true,
      },
      matchedTypes: {},
      filterlessMatches: undefined 
    }
  },
  computed: {
    ...mapGetters("internal" , ['hasSidebarOpen', 'fluxSearchMatched']),
  },
  methods: {
    clearSearch(closeSearcher = true){
      if (closeSearcher){
        this.showingSearcher = false
        this.fluxSearchTerm = ""
      }
      this.searchResult = {
        matches: undefined,
        navIndex: 1,
        paramsUsed: {
          term: "",
          matchWholeWord: false,
          caseSensitive: false,
        }
      }
      this.viewDetails = false
      this.matchedTypes = {}
      this.$store.dispatch('internal/setMatchedFluxSearch', [])
    },
    focusSearch(){
      this.showingSearcher = true;
      this.$refs['flux-search-input'].focus()
    },
    searchInFlux(){

      let params = structuredClone(this.searchResult.paramsUsed)
      if ( params && 
        params.term == this.fluxSearchTerm &&
        params.matchWholeWord == this.searchOptions.matchWholeWord &&
        params.caseSensitive == this.searchOptions.caseSensitive )
        {
          this.shiftMatchedIndex(+1)
          return 
        }
      
      let term = structuredClone(this.fluxSearchTerm)
      if (!term.length){
        this.clearSearch(false)
        return
      }
      this.$emit("fluxSearch", term, this.searchOptions.caseSensitive, this.searchOptions.matchWholeWord);
      this.viewDetails = false
      this.resultTypeShowing = {
        'agentSource': true,
        'agentValue': true,
        'agentType': true,
        'middlewareName': true,
        'middlewareType': true,
      }
    },
    fluxSearchReturn(result){//refed down from flux
      this.searchResult.matches = result
      this.searchResult.navIndex = 1
      this.searchResult.paramsUsed = structuredClone({
          term: this.fluxSearchTerm,
          matchWholeWord: this.searchOptions.matchWholeWord,
          caseSensitive: this.searchOptions.caseSensitive,
        })
      this.calculateMatchedTypes()
      
      this.filterlessMatches = structuredClone(this.searchResult.matches)
      this.$store.dispatch('internal/setMatchedFluxSearch', result)
      this.$store.dispatch('internal/setMatchedFluxIndex', 1)

      
      anime({
        targets:".search-result-container",
        "max-width": ['0px','350px'],
        duration: 4000,
        delay: 100,
      })
    },
    updateSearchFilter(){
      this.searchResult.matches = structuredClone(this.filterlessMatches)

      let r = []
      this.searchResult.matches.forEach(match => {
        if (this.resultTypeShowing[match.matchType]){
          r.push(match)
        }
      });

      this.searchResult.matches = r;
      this.searchResult.navIndex = 1
      this.$store.dispatch('internal/setMatchedFluxSearch', r);

      // this.calculateMatchedTypes()
    },
    calculateMatchedTypes(){
      let matches = structuredClone(this.searchResult.matches)
      if (!matches){
        return {}
      }

      let typeAmout = {
        "agentValue": 0,
        "agentSource": 0,
        "agentType": 0,
        "middlewareType": 0,
        "middlewareName": 0,
      }

      matches.forEach(m => {
        typeAmout[m.matchType] = typeAmout[m.matchType] + 1 
      });

      this.matchedTypes = typeAmout
    },
    shiftMatchedIndex(value){
      if (!Array.isArray(this.searchResult.matches)){
        return
      }
      let r = this.searchResult.navIndex + value 
    
      if (r > 0 && r <= this.searchResult.matches.length){
        this.searchResult.navIndex = r
        this.$store.dispatch('internal/setMatchedFluxIndex', r)
      } else {
        this.$store.dispatch('internal/setMatchedFluxIndex', undefined)
        
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.flux-search-input{
  transition: all 0.1s;
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
  &:focus{
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
  }
}

.search-container{
  width: 100%;
}

.search-options-container{
  padding-bottom: 2px;
}

.search-result-container{
  margin-top: 4px;
  font-size: 11px;
  font-weight: bolder;
  width: fit-content;
  white-space: nowrap;
  min-height: 20px;
  overflow-x: hidden;
  >*{
    margin-left: 5px;
  }
  .index-container{
    display: inline-block;
    min-width: 15px;
    text-align: center;
  }
}

.search-outer-container{
  transition: all 0.2s;
  padding-bottom: 10px;
  margin: 10px;
  width: 400px;
  
  background-color: rgba(15, 20, 34, 0.85);
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.5);

  h5{
    letter-spacing: 1px;
    font-weight: bolder;
  }
}

.close-searcher-btn{
  border-radius: 0 0 10px 0;
  padding: 2px 0;
  background-color: rgba(0, 0, 0, 0.8);
  letter-spacing: 4px;
  color: $light;
}

.selection-orange{
  color: $selection-orange ;
}

.result-details{
  white-space: normal;
  border: 1px solid make-darker($success);
  padding: 5px;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 280px;
  font-size: 12px;
  background-color: lower-opacity($success, .95);
}

.low-opacity-label{
  opacity: 0.35;
}
</style>