import store from "@/store";

let global_SourceInput_IdMap = {};
let global_SourceInput_PathMap = {};
let global_SourceInput_PosSources = {};

let global_SourceInput_Fetching = false;

export default class Register {
  constructor({ label, prefix = "$", selectable = true, helper = undefined, id_selected = undefined }) {
    this.prefix = prefix;
    this.label = label;
    this.selectable = selectable;
    this.helper = helper;
    this.id_selected = id_selected;
    this.idMap = {};
    this.pathMap = {};
    this.posSources = [];
  }

  getVariables() {
    return new Promise(async (resolve, reject) => {
      while(global_SourceInput_Fetching) {
        await this.wait(100);
      }

      if(Object.keys(global_SourceInput_IdMap).length) {
        this.idMap = global_SourceInput_IdMap;
        this.pathMap = global_SourceInput_PathMap;
        this.posSources = global_SourceInput_PosSources;
        resolve();
        return;
      }

      global_SourceInput_Fetching = true;

      store
        .dispatch("getEnumVariables")
        .then((response) => {
          this.resolveSource(response, "$source");
          global_SourceInput_IdMap = this.idMap;
          global_SourceInput_PathMap = this.pathMap;
          global_SourceInput_PosSources = this.posSources;

          global_SourceInput_Fetching = false;

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  resolveSource(raw, currentPath) {
    if (!(raw instanceof Object)) {
      if (currentPath.indexOf(this.prefix) != 0) {
        return;
      }
      this.idMap[raw] = currentPath;
      this.pathMap[currentPath] = raw;
      this.posSources.push({ path: currentPath, id: raw });
    } else {
      Object.keys(raw).forEach((key) => {
        if (key == "params") {
          return;
        }
        this.resolveSource(raw[key], `${currentPath}.${key}`);
      });
    }
  }

  wait(time) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, time);
    });
  }
}
