export default class EnumVariableCategories {
  constructor() {
    this.items = [
      {
        "id": 1,
        "label": "enum.variable.category.environment",
        "script_tag": "env"
      },
      {
        "id": 2,
        "label": "enum.variable.category.real_time",
        "script_tag": "realtime"
      },
      {
        "id": 3,
        "label": "enum.variable.category.system",
        "script_tag": "system"
      }
    ]
  }
}