import Agent from '@/custom/class/Agent/Agent.js'
import Blocks from "@/custom/class/Enum/TryCatch";

const blocks = new Blocks().items
export default class NormalizedAgent {
  constructor({
    id = null,
    enum_agent_action = new Agent(3),
    register_1 = {},
    register_2 = {},
    register_destiny = {},
    enum_agent_block = blocks[0],
    fatal_on_fail = true,
    execution_order = 1,
  }) {
    this.id = id
    this.enum_agent_action = new Agent(enum_agent_action?.id || enum_agent_action)
    this.register_1 = {
      source: register_1.source,
      value: register_1.value
    }
    this.register_2 = {
      source: register_2.source,
      value: register_2.value
    }
    this.register_destiny = {
      source: register_destiny.source,
      value: register_destiny.value
    }
    this.enum_agent_block = enum_agent_block
    this.fatal_on_fail = fatal_on_fail;
    this.execution_order = execution_order;
  }
}