import Methods from "./../Enum/Methods"

export default class Method {
    constructor(id) {
        const met = new Methods();
        const item = met.items.find(el => el.id == id )

        Object.assign(this, item);
    }
}


// method: {
//     'GET': "light-success",
//     'POST': "light-warning",
//     'PUT': "light-info",
//     'DELETE': "light-danger",
//     'PATCH': "light-secondary",
//   },