import NormalizedAgent from '@/custom/class/NormalizedAgent';
import { makeToast } from "@/layouts/components/Popups";
import Blocks from "@/custom/class/Enum/TryCatch";


export function isEmpty(payload) {
  if (JSON.stringify(payload) === '{}'  || payload == null || payload == [] || !payload){
    return true
  }
  return false
}

export function validate(vue) {

  const checkRegister1 = vue.register_1_optional ? true : !isEmpty(vue.agent.register_1.value);
  
  const checkRegister2 = vue.register_2_optional ? true : !isEmpty(vue.agent.register_2.value);
  
  const checkRegisterDestiny = vue.register_destiny_optional ? true : !isEmpty(vue.agent.register_destiny.value);

  return checkRegister1 && checkRegister2 && checkRegisterDestiny;
}

export function save(vue, emmitSaved=true) {
    vue.isSaving = true
    if (!vue.agentID) {
      vue.insertAgent({
        middleware: vue.middlewareID,
        enum_agent_action: parseInt(vue.actionID),
        enum_agent_block_id: vue.agent.enum_agent_block.id,
        enum_source_register_1: vue.toSaveRegs.register_1 ? vue.agent.register_1.source.toString() : null,
        register_1_value: vue.toSaveRegs.register_1 ? vue.agent.register_1.value.toString() : '',
        enum_source_register_2: vue.toSaveRegs.register_2 ? vue.agent.register_2.source.toString() : null,
        register_2_value: vue.toSaveRegs.register_2 ? vue.agent.register_2.value.toString() : '',
        enum_source_destiny_id: vue.toSaveRegs.register_destiny ? vue.agent.register_destiny.source.toString() : null,
        destiny_value: vue.toSaveRegs.register_destiny ? vue.agent.register_destiny.value.toString() : '',
        fatal_on_fail: vue.agent.fatal_on_fail,
        transmissionID: vue.$route.params.transmissionID
      })
        .then((response) => {
          vue.$emit('saved', { agent: vue.agent, response }, emmitSaved)
          makeToast({
            title: vue.$t("agent.toast.create.success.title"),
            text: vue.$t("agent.toast.create.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });
        })
        .catch(() => {
          makeToast({
            title: vue.$t("agent.toast.create.error.title"),
            text: vue.$t("agent.toast.create.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        })
        .finally(()=>{
          vue.isSaving = false
        })
    } else {
      vue.updateAgent({
        id: vue.agentID,
        enum_agent_block_id: vue.agent.enum_agent_block.id,
        enum_source_register_1: vue.toSaveRegs.register_1 ? vue.agent.register_1.source.toString() : null,
        register_1_value: vue.toSaveRegs.register_1 ? vue.agent.register_1.value.toString() : null,
        enum_source_register_2: vue.toSaveRegs.register_2 ? vue.agent.register_2.source.toString() : null,
        register_2_value: vue.toSaveRegs.register_2 ? vue.agent.register_2.value.toString() : null,
        enum_source_destiny_id: vue.toSaveRegs.register_destiny ? vue.agent.register_destiny.source.toString() : null,
        destiny_value: vue.toSaveRegs.register_destiny ? vue.agent.register_destiny.value.toString() : null,
        fatal_on_fail: vue.agent.fatal_on_fail,
        transmissionID: vue.$route.params.transmissionID
      })
        .then((response) => {
          vue.$emit('saved', { agent: vue.agent, response }, emmitSaved)
          
          makeToast({
            title: vue.$t("agent.toast.update.success.title"),
            text: vue.$t("agent.toast.update.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });
        })
        .catch(() => {
          makeToast({
            title: vue.$t("agent.toast.create.error.title"),
            text: vue.$t("agent.toast.create.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        })
        .finally(()=>{
          vue.isSaving = false
        })
    }
}

function makeToastGetError(vue) {
  makeToast({
    title: vue.$t("agent.toast.get.error.title"),
    text: vue.$t("agent.toast.get.error.message"),
    variant: "danger",
    icon: "XIcon",
  });
}

export function fetchAgentByID(vue) {
    const agent_block = new Blocks()
    if (vue.agentID) {
      if (!vue.agent) {
        vue.getAgentByID({ agentID: vue.agentID, transmissionID: vue.$route.params.transmissionID })
        .then((response) => {
          vue.agent = 
          new NormalizedAgent({
            id: response.id,
            register_1: {
              source: response.enum_source_register_1,
              value: response.register_1_value
            }, 
            register_2: {
              source: response.enum_source_register_2,
              value: response.register_2_value
            },
            register_destiny: {
              source: response.enum_source_destiny_id,
              value: response.destiny_value
            },
            enum_agent_block: agent_block.items[response.enum_agent_block.id -1],
            enum_agent_action: response.enum_agent_action,
            fatal_on_fail: response.fatal_on_fail,
            execution_order: response.execution_order,
          })
        })
        .catch((err) => {
          console.log(err)
          makeToastGetError(vue)
        });
      }
    } else {
      vue.agent = setAgent(null, vue.defaultAgent)
    }
}

export function setAgent(_, payload) {
  const agent_block = new Blocks()

  return new NormalizedAgent({
    id: payload.id,
    register_1: {
      source: payload.register_1 ? payload.register_1.source : undefined,
      value: payload.register_1 ? payload.register_1.value : undefined,
    }, 
    register_2: {
      source: payload.register_2 ? payload.register_2.source : undefined,
      value: payload.register_2 ? payload.register_2.value : undefined,
    },
    register_destiny: {
      source: payload.register_destiny ? payload.register_destiny.source : undefined,
      value: payload.register_destiny ? payload.register_destiny.value : undefined,
    },
    enum_agent_block: agent_block.items[payload.agent_block_id -1],
    enum_agent_action: payload.enum_agent_action_id,
    fatal_on_fail: payload.fatal_on_fail,
    execution_order: payload.executionOrder,
  });
}

export function defineToAPI(payload, middlewareID) {
  return {
    agent_id: payload.id || null,
    middleware: middlewareID,
    enum_agent_action: payload.enum_agent_action.id,
    enum_agent_block_id: payload.enum_agent_block.id,
    enum_source_destiny_id: parseInt(payload.register_destiny.source) || null,
    destiny_value: payload.register_destiny.value || null,
    enum_source_register_1: parseInt(payload.register_1.source) || null,
    register_1_value: payload.register_1.value || null,
    enum_source_register_2: parseInt(payload.register_2.source) || null,
    register_2_value: payload.register_2.value || null,
    fatal_on_fail: payload.fatal_on_fail,
  }
}
export function getValueFrom(options, agents) {
  let data = undefined;
  let value = null;

  agents.forEach((element) => {
    const check_for_value = options.value != undefined;

    if (check_for_value) {
      value = element[options.from.value].value;
      if (options.modifier) {
        value = options.modifier(value);
      }
    }

    //----------------------------------------------
    // this is a special addition to the "getValueFrom" function, in almost all other Middlewares it's the same, but here there's a caviat - 'confirmationFunction'
    //if these are ever generalized in a single file this section should be in - it's made to not affect anything that doesn't use it
    let confirm = true;
    if (options.confirmationFunction){
      confirm = options.confirmationFunction(element)
    }//----------------------------------------------

    if (
      element[options.from.source].source == options.source &&
      (!check_for_value || value == options.value) &&
      confirm
    ) {
      if (options.data_modifier) {
        element = options.data_modifier(element);
      }

      if (!options.multiple) {
        data = element;
        return;
      }
      if (data == undefined) data = [];

      data.push(element);
    }
  });

  if (data == undefined) {
    data = options.default;
  }

  return data;
}

export default {
  isEmpty,
  validate,
  save,
  fetchAgentByID,
  setAgent,
  defineToAPI
}