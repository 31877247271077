<template>
  <span 
    :id="id" 
    class="middleware-line"
    :data-current-middleware-id="currentItem"
    :data-next-middleware-id="nextItem"
  />
</template>

<script>

  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      currentItem: {
        type: [ String, Number ],
        required: true,
      },
      nextItem: {
        type: [ String, Number ],
        required: true,
      },
    },
  }
</script>

<style lang="scss">
.middleware-line { 
  border-right: 1px solid #525252; 
  position: absolute;
}
</style>