<template>
  <div class="w-fit-content">
    <b-collapse :visible="previewVisible" appear @hidden="$emit('closed')">
      <div class="outer-container">
        <h4 class="font-weight-bolder text-center bg-dark-blue py-25 position-relative cursor-drag">
          <span class="page-subtitle">
            Middlewares
            <feather-icon icon="GitPullRequestIcon"/>
          </span>

          <b-button class="close-preview-btn" variant="none" @click="previewVisible = false;">
            <b-icon icon="x" scale="1.3"/>
          </b-button>
        </h4>
        
        <div class="px-50">
          <div class="d-flex align-items-center justify-content-start flex-wrap mb-25">
            <div>
              <span v-if="category.service" class="mr-2">
                <span class="small text-secondary">Service:</span> <b>{{category.service}}</b>
              </span>
            </div>
            <div>
              <span v-if="category.event">
                <span class="small text-secondary">Event:</span>  <b>{{category.event}}</b>
              </span>
            </div>
          </div>
          
          <h4 class="">
            <b-badge v-if="category.service" variant="light-success">Service</b-badge>
            <span v-if="category.service && category.event">
              <b-icon icon="plus" variant="light" shift-v="-2"/>
            </span>
            <b-badge v-if="category.event" variant="light-warning">Event</b-badge>

            <b-badge v-if="!category.event && !category.service" variant="light-info">FREE</b-badge>


            <b-badge variant="light-secondary text-light" class="mx-75 in-out-badge float-right">
              <span class="text-dark" v-if="!category.isOut">IN</span>
              <span class="text-dark" v-else>OUT</span>
            </b-badge>
          </h4>
        </div>
        <div class="middleware-display">
          <script-middlewares-display :middlewares="middlewares"/>
        </div>

        <!-- <b-button class="copy-middleware-preview-btn" variant="none" @click="showModalExportMiddlewares(middlewares)">
          <span class="">Export Middlewares</span>
        </b-button> -->
        
      </div>
    </b-collapse>

    <modal-export-middlewares ref="modal-export-middlewares" :middlewaresToExport="middlewaresExporting"/>
  </div>
</template>

<script>
import { 
  BCol,
  BRow,
  BIcon,
  BButton,
  BCollapse,
  BBadge
 } from 'bootstrap-vue';
import ScriptMiddlewaresDisplay from '@/views/pages/middleware/MiddlewareManagement/Components/FluxOverview/ScriptMiddlewaresDisplay.vue';
import ModalExportMiddlewares from "@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/ModalExportMiddlewares.vue";

export default {
  components: {    
    BCol,
    BRow,
    BIcon,
    BButton,
    BCollapse,
    BBadge,
    ScriptMiddlewaresDisplay,
    ModalExportMiddlewares
  },
  data() {
    return {
      previewVisible: true,
      middlewaresExporting: undefined
    }
  },
  props: {
    category: {
      type: Object,
      required: true 
    },
    middlewares: {
      type: [Object, Array],
      required: true 
    },
  },
  watch: {
    middlewares(newValue, oldValue) {
      this.previewVisible = true
    }
  },
  methods: {
    showModalExportMiddlewares(middlewares) {
      this.middlewaresExporting = middlewares
      this.$refs['modal-export-middlewares'].show()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.in-out-badge{
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.middleware-display{
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 15px 5px 15px;  
  background-color: rgba(0, 0, 0, 0.15);
  width: fit-content;
  max-height: 400px;
  width: 100%;
  min-width: 300px;
  overflow-y: auto;
  
}

.outer-container{
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #283046;
  cursor: grab;
}

.close-preview-btn{
  position: absolute;
  right:0;
  padding: 2px 10px;
  
}
.copy-middleware-preview-btn{
  width: 100%;
  background-color: #22293c;
  padding: 3px 5px;
  border-radius: 0 0 4px 4px ;
  
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  *{
    font-size: 12px;
    color: $secondary;
  }
  &:hover{
    background-color: #252c41;
    *{
      color:  transparentize($light, 0.3);
    }
  }
}
</style>