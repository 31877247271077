<template>
  <div>
    
    <b-row no-gutters>
      <b-col cols="6" class="px-50 mt-1">
        <label class="mx-25">Username</label>
        <b-input-group>
          <b-form-input v-model="data['user']" :disabled="isFieldDisabled('user')"/> 
          <b-input-group-append v-if="editFieldsIndividually">
              
            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('user')" v-if="!editingFields.includes('user')">
              <b-icon icon="pencil"/>
            </b-button>
            <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('user')" v-else>
              <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
            </b-button>

          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col cols="6" class="px-50 mt-1">
        <label class="mx-25">Password</label>
        <b-input-group >
          <b-form-input v-model="data['pass']" :disabled="isFieldDisabled('pass')" :type="!editingFields.includes('pass') ? 'password' : 'text' " v-if="(editingFields.includes('pass') || !editFieldsIndividually)"/>
          <b-form-input v-model="mockPassword" :disabled="isFieldDisabled('pass')" v-else/>

        <b-input-group-append v-if="editFieldsIndividually">
          <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('pass')" v-if="!editingFields.includes('pass')">
            <b-icon icon="pencil"/>
          </b-button>
          <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('pass')" v-else>
            <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
          </b-button>
        </b-input-group-append>
        <b-input-group-append v-else>
          <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('pass')" v-if="!editingFields.includes('pass')">
            <b-icon icon="eye"/>
          </b-button>
          <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.splice(editingFields.indexOf('pass') , 1)" v-else>
            <b-icon icon="eye-slash"/>
          </b-button>
        </b-input-group-append>
        
      </b-input-group> 
      </b-col>


      <b-col cols="6" class="px-50 mt-1">
        <label class="mx-25">Host</label>
        <b-input-group>
          <b-form-input v-model="data['host']" :disabled="isFieldDisabled('host')" :state="fieldStates['host']" @input="fieldStates['host'] = null"/> 

          <b-input-group-append v-if="editFieldsIndividually">

            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('host')" v-if="!editingFields.includes('host')">
              <b-icon icon="pencil"/>
            </b-button>
            <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('host')" v-else>
              <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
            </b-button>
            

          </b-input-group-append>
        </b-input-group>

      </b-col>

      <b-col cols="6" class="px-50 mt-1">
        <label class="mx-25">Port</label>
        <b-input-group class="number-input-hide-caret" >
          <b-form-input type="number" v-model="data['port']" :disabled="isFieldDisabled('port')" @input="data['port'] = parseInt(data['port']); fieldStates['port'] = null" :state="fieldStates['port']"/> 
          <b-input-group-append v-if="editFieldsIndividually">

            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('port')" v-if="!editingFields.includes('port')">
              <b-icon icon="pencil"/>
            </b-button>
            <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('port')" v-else>
              <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
            </b-button>
            

          </b-input-group-append>
        </b-input-group>

      </b-col>

      <b-col cols="6" class="px-50 mt-1">
        <label class="mx-25">Database</label>
        
        <b-input-group>
          <b-form-input v-model="data['connection_database']" :disabled="isFieldDisabled('connection_database')" :state="fieldStates['connection_database']" @input="fieldStates['connection_database'] = null"/>
          <b-input-group-append v-if="editFieldsIndividually">
            
            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('connection_database')" v-if="!editingFields.includes('connection_database')">
              <b-icon icon="pencil"/>
            </b-button>
            <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('connection_database')" v-else>
              <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
            </b-button>

          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col cols="12"/>
      <b-col cols="3" class="px-50 mt-1">
        <div class="mt-0 mb-1">
          <label class="ml-25">SSL:</label>

          <v-select class="" :clearable="false" :options="sslOptions" v-model="data['tls']" :disabled="isFieldDisabled('tls')"></v-select>

        </div>
      </b-col>
      <b-col :cols="editFieldsIndividually ? 8 : 9" class="px-1 mt-1" v-if="['verify-ca', 'verify-full'].includes(data.tls)">
        <b-collapse :visible="true" appear>
          <div class="mt-0 mb-1">
            <label class="">Certificate:</label>

            <b-input-group>
              <b-form-textarea class="controlled-height" v-model="data['certificate']" :disabled="isFieldDisabled('tls')" v-if="!isFieldDisabled('tls')"/> 
              <b-form-textarea class="controlled-height" v-model="mockPassword" :disabled="true" v-else/> 
            </b-input-group>

          </div>
        </b-collapse>
      </b-col>
      <b-col v-if="editFieldsIndividually" cols="1" class="mt-1">
        <b-button variant="none" class="mt-2 px-50" @click="editingFields.push('tls')" v-if="!editingFields.includes('tls')">
          <b-icon icon="pencil"/>
        </b-button>
        <b-button v-else variant="none" class="text-success mt-2" @click="saveChangeSsl()">
          <b-icon icon="check2" class="mr-25"/> Save 
        </b-button> 
      </b-col>
    </b-row>
    
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import ConnectionTypes from '@/custom/class/Enum/ConnectionTypes.js'
import createConnectionModal from "@/views/pages/middleware/MiddlewareManagement/Components/Modals/CreateConnectionModal.vue"
import connectionStatus from '@/custom/class/Enum/ConnectionStatus.js'
import { makeToast } from '@/layouts/components/Popups';
import VSelect from "vue-select";

import {
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BButton,
  BTable,
  BBadge,
  BSpinner,
  BCollapse,
  BCard,
  BFormCheckbox,
  BFormTextarea
} from "bootstrap-vue";

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    createConnectionModal,
    BSpinner,
    BCollapse,
    BCard,
    BFormCheckbox,
    VSelect,
    BFormTextarea
  },
  data() {
    return {
      fieldsDictionary:{
        user: 'user',
        pass: 'pass',
        port: 'port',
        connection_database: 'database',
        host: 'host',
      },

      requiredFields:['port', 'connection_database', 'host'],
      fieldStates: {
        user: null,
        pass: null,
        port: null,
        connection_database: null,
        host: null
      },

      defaultValues: {
        port: 5432,
        tls: 'disabled'
      },

      sslOptions: ["disabled", "require", "verify-ca", "verify-full"],
      editingFields: [],
      mockPassword: '* * * * * * *',
      
    }
  },
  props: {
    data:{
      type: Object,
      default: undefined 
    },
    editFieldsIndividually:{
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('connection',['getConnections']),
    connection: {
      get() {
        return this.data;
      },
      set(data) {
        this.$emit("input", data);
      },
    },

    connections(){
      return this.getConnections
    },
    organizationID() {
      return localStorage.getItem('selectedOrganization')
    },
  },
  mounted() {
    if (!this.editFieldsIndividually){
      Object.keys(this.defaultValues).forEach(key => {
        this.$set(this.data, key, this.defaultValues[key])
      });
    }
  },
  methods: {
    
    getTypeByID(id){
      let type = new ConnectionTypes().items.find(el=>el.id == id)
      return type
    },
    getEnumStatus(id = undefined){
      if (id){
        let item = new connectionStatus().items.find(el=>el.id == id)
        return item
      } else {
        return new connectionStatus().items
      }
    },
    saveFieldChange(field){
      {
        let idx = this.editingFields.findIndex(el=> el == field)
        if (idx > -1){
          this.editingFields.splice(idx, 1)
        }
      }

      let convField = this.fieldsDictionary[field]

      let payload = {
        [convField] : this.data[convField] || this.data[field],
        organization: this.organizationID,
        connection: this.data.id
      }      
           
      this.$store.dispatch('connection/patchConnectionField', payload).then((resp)=>{
        makeToast({
          title: `Saved!`,
          text: `Connection successfully updated`,
          variant: 'success',
          icon: 'CheckIcon'
        })
      })
      .catch((err)=>{
        console.error(err)
        makeToast({
          title: `Error!`,
          text: `Connection couldn't be updated`,
          variant: 'danger',
          icon: 'XIcon'
        })
      })
      .finally(()=>{
      })

    },
    saveChangeSsl(){
      {
        let idx = this.editingFields.findIndex(el=> el == 'tls')
        if (idx > -1){
          this.editingFields.splice(idx, 1)
        }
      }

      let payload = {
        tls : String(this.data['tls']),
        organization: this.organizationID,
        connection: this.data.id
      }      
           
      this.$store.dispatch('connection/patchConnectionField', payload).then((resp)=>{
        makeToast({
          title: `Saved!`,
          text: `Connection successfully updated`,
          variant: 'success',
          icon: 'CheckIcon'
        })
        if (this.data['tls'] && ["verify-ca", "verify-full"].includes(String(this.data['tls']))){

          let newPayload = {
            certificate : String(this.data['certificate']),
            organization: this.organizationID,
            connection: this.data.id
          }     

          this.$store.dispatch('connection/patchConnectionField', newPayload).then(()=>{

          })
        }
      })

    },
    isFieldDisabled(field){      
      let check = this.editingFields.includes(field)
      return !check && this.editFieldsIndividually
    },
    areInputsValid(){
      let r = true
      
      this.requiredFields.forEach(field => {
        if (!this.data[field] || this.data[field].length <= 0){
          this.fieldStates[field] = false
          r = false
        }
      });

      return r
    }
  }
};
</script>

<style lang="scss">

.number-input-hide-caret{
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}


.controlled-height{
  max-height: 38px;
}
</style>