import Direction from "./Direction";

export default class Category {
    constructor({ id = undefined, identifier = undefined, label = undefined, fetch = undefined, event_id = undefined, service_id = undefined, webhook_id = undefined, direction = 1 }) {
        this.label = label;
        this.id = id;
        this.identifier = identifier;
        this.fetch = fetch;
        this.event_id = event_id;
        this.service_id = service_id;
        this.webhook_id = webhook_id;
        this.direction = new Direction(direction)
    }
}