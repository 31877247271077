<template>
  <div>
    
    <b-collapse :visible="true" appear>
      <b-card
        class="middleware-card p-0 mb-1"  
        body-class="p-0 m-0 d-flex align-items-center justify-content-between"
      >
        <div class="d-flex justify-content-start align-items-center">
          <b-skeleton height="45px" width="45px" class="my-0 mx-1" animation="fade"/>
          <div class="">
            <b-skeleton height="20px" width="238px" class="mt-0 mb-50" animation="fade"/>
            <b-skeleton height="12px" width="80px" class="my-0" animation="fade"/>
          </div>
        </div>
        
        <div class="d-flex justify-content-end align-items-center px-1">
          <b-skeleton height="25px" width="25px" class="my-0 mx-1 round" animation="fade" />
        </div>
      </b-card>
      
        <svg-arrow
          v-if="!hideArrow"
          class="mb-25 zoom-it pointer-events-none"
          :direction="arrowType"
          scale="0.5" 
          :width="arrowWidth"
          height="70"
          event="addMiddleware" 
        />  

        <template v-if="hasChildren">
          <div>
            <b-card
            class="middleware-card p-0 mb-1"  
            body-class="p-0 m-0 d-flex align-items-center justify-content-between"
          >
            <div class="d-flex justify-content-start align-items-center">
              <b-skeleton height="45px" width="45px" class="my-0 mx-1" animation="fade"/>
              <div class="">
                <b-skeleton height="20px" width="250px" class="mt-0 mb-50" animation="fade"/>
                <b-skeleton height="12px" width="80px" class="my-0" animation="fade"/>
              </div>
            </div>
            
            <div class="d-flex justify-content-end align-items-center px-1">
              <b-skeleton height="25px" width="25px" class="my-0 mx-1 round" animation="fade" />
            </div>
          </b-card>

            <svg-arrow
              class="mb-25 zoom-it pointer-events-none "
              :direction="arrowType"
              scale="0.5" 
              :width="arrowWidth"
              height="70"
              event="addMiddleware" 
            />
          </div>
        </template>

    </b-collapse>
  
  </div>
</template>

<script>
import { BCard, BAvatar, BIcon, BFormInput, BTooltip, BBadge, BSkeleton, BCollapse } from 'bootstrap-vue';
import Method from '@/custom/class/Integrator/Method'
import SvgArrow from '@/layouts/components/SvgArrow.vue'
import { integer } from 'vee-validate/dist/rules';
 

  export default {
    components: {
      BCard, 
      BAvatar, 
      BIcon,
      BFormInput, 
      BTooltip,
      BBadge,
      SvgArrow,
      BSkeleton,
      BCollapse
    },
    props: {
      arrowType: {
        type: String,
        required: true
      },
      arrowWidth: {
        type: Number,
        required: true
      },
      hasChildren: {
        type: Boolean,
        default: false
      },
      hideArrow: {
        type: Boolean,
        default: false
      },
    },
  }
</script>

<style lang="scss" scoped>

.limited-width{
  max-width: 320px;
  max-height: 15px;
  overflow-x: auto !important;
  overflow-y: hidden;
  word-break: break-all !important;
  word-wrap: break-word !important;
  box-shadow: inset -50px 0px 19px -15px transparent;
}

.middleware-card {
  max-width: 450px;
  min-width: 450px;
  height: 70.5px;
  width: 100%;
  box-sizing: content-box !important; 
  border-left: 7px solid rgb(22, 29, 49);
}


@keyframes animation-insert-middleware {
  0%   {
    transform: scaleY(0.1);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes animation-expand-on-mount {
  0%   {
    transform: scaleY(0.1);
    max-height: 0px;
  }
  100% {
    transform: scaleY(1);
    max-height: 200px !important;

  }
}

.animation-insert-middleware{
  animation-name: animation-insert-middleware;
  animation-duration: 0.45s;
  animation-iteration-count: 1;
}

.pointer-events-none{
  pointer-events: none;
}

.expand-on-mount{
  animation-name: animation-expand-on-mount;
  animation-duration: 1s;
  animation-iteration-count: 1;
  -webkit-transition: max-height 1s; 
  -moz-transition: max-height 1s; 
  -ms-transition: max-height 1s; 
  -o-transition: max-height 1s; 
  transition: max-height 1s;  
}
</style>