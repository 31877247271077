<template>
  <b-modal
    :id="uuid"
    ok-only
    :ok-title="$t('middleware.modal.select_agent.ok')"
    @ok="addAgent"
    lazy
    no-close-on-backdrop
    centered
    :title="$t('middleware.modal.select_agent.title')"
    ref="select-agent-modal"
  >
    <slot name="header">
    </slot>
    <v-select
      :clearable="false"
      v-model="agent_item"
      :options="agentList"
      label="label"
      :reduce="(item) => item"
      :getOptionLabel="(item) => $t(item.label)"
      @input="setAgentItem"
    >
    </v-select>

    <template #modal-footer>
      <div class="m-0 p-0">
        <b-button variant="success" @click="addAgent(); $refs['select-agent-modal'].hide()" ref="confirm-button">{{$t('middleware.modal.select_agent.ok')}}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { 
  BModal,
  BButton
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import Agents from "@/custom/class/Enum/Agents";


  export default {
    components: {
      BModal,
      BButton,
      VSelect,
    },
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        agent_item: null,
        agentList: null
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      init() {
        this.setAgentsList()
      },
      addAgent() {
        this.$emit('addAgent')
      },
      setAgentsList() {
        const agents = new Agents();
        this.agentList = agents.items.filter(el => el.active);
        this.$nextTick(()=>{
          this.agent_item = this.agentList.find(el=> el.component == "agent-remap") || this.agentList[0];
          this.setAgentItem(this.agent_item)
        })
      },
      setAgentItem(item) {
        this.$emit('selected', item)
        this.focusConfirm();
      },
      focusConfirm(){
        this.$nextTick(()=>{
          if (this.$refs['confirm-button']){
            this.$refs['confirm-button'].focus();
          }
        })
      }        
    },
  }
</script>

<style lang="scss" scoped>

</style>