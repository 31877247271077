<template>
  <div>

    <category-header
      v-model="category"
      @directionChanged="refreshCurrentCategory"
      @categoryChanged="refreshCurrentCategory"
    />
    <div class="">
      <div class="category-container shadow">
        <flux
          v-if="currentCategory"
          :ref="getID('flux')"
          v-model="category"

          :direction="currentCategory.direction.id"
        />
      </div>
    </div>


  </div>
</template>

<script>
import { BCol, BRow, BButton, BIcon } from "bootstrap-vue";
import Category from "@/custom/class/Middleware/Category";
import Flux from "../../Flux.vue";
import CategoryHeader from "./Header.vue";
import { v4 as uuidv4 } from "uuid";
import Categories from "@/custom/class/Enum/Categories";

export default {
  components: {
    Flux,
    BCol,
    BRow,
    BButton,
    BIcon,
    CategoryHeader,
  },
  data() {
    return {
      category: this.lastAccessCategory(),
      currentCategory: undefined,
      uuidMap: {},
    };
  },
  computed: {
    nextCategory() {
      let next = new Categories().getNext(this.category.id);
      next.locked = false;
      if (
        (next.require.event &&
          (this.category.event_id === null ||
            this.category.event_id === undefined)) ||
        (next.require.service &&
          (this.category.service_id === null ||
            this.category.service_id === undefined))
      ) {
        next.locked = true;
      }

      return next;
    },
    previousCategory() {
      let previous = new Categories().getPrevious(this.category.id);

      previous.locked = false;
      if (
        (previous.require.event &&
          (this.category.event_id === null ||
            this.category.event_id === undefined)) ||
        (previous.require.service &&
          (this.category.service_id === null ||
            this.category.service_id === undefined))
      ) {
        previous.locked = true;
      }

      return previous;
    }
  },
  mounted() {
    this.currentCategory = new Category(
      structuredClone(this.category)
    );
    this.refreshCurrentCategory();
  },
  methods: {
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    refreshMiddlewares() {
      this.$refs[this.getID("flux")].refreshMiddlewares();
    },
    refreshCurrentCategory() {
      this.currentCategory.direction = this.category.direction;

      const cat = structuredClone(this.category);

      switch (this.category.identifier) {
        case "free":
          {
            this.currentCategory.event_id = null;
            this.currentCategory.service_id = null;
          }
          break;
        case "service":
          {
            this.currentCategory.event_id = null;
            this.currentCategory.service_id = cat.service_id;
          }
          break;
        case "event":
          {
            this.currentCategory.event_id = cat.event_id;
            this.currentCategory.service_id = null;
          }
          break;
        case "service_event":
          {
            this.currentCategory.event_id = cat.event_id;
            this.currentCategory.service_id = cat.service_id;
          }
          break;
      }

      this.$nextTick(() => {
        this.refreshMiddlewares();
      });
    },
    goPreviousCategory() {
      const prv = this.previousCategory;

      if(prv.locked) {
        return;
      }

      this.category.id = prv.id;
      this.category.identifier = prv.identifier;
      this.category.label = prv.label;

      this.refreshCurrentCategory();
    },
    goNextCategory() {
      const nxt = this.nextCategory;

      if(nxt.locked) {
        return;
      }

      this.category.id = nxt.id;
      this.category.identifier = nxt.identifier;
      this.category.label = nxt.label;

      this.refreshCurrentCategory();
    },
    lastAccessCategory(){
      let r;
      r = localStorage.getItem('lastAccessInfo')
      
      if (r && r.length && (r!='null') &&  r!= null){
        r = JSON.parse(r)
        r = r[this.$route.params.transmissionID]
        if (!r){
          r = {service_id: undefined, event_id: undefined, dir_id: 1}
        }
      }else{
        r = {service_id: undefined, event_id: undefined, dir_id: 1}
      }
      
      const service = !!r.service_id;
      const event = !!r.event_id;
      let cat;
      
      switch (true) {
        case (!service && !event):
          cat = new Categories().items.find(el=> el.id == 1)          
          break;
        case (service && !event):
          cat = new Categories().items.find(el=> el.id == 2)
          break;
        case (!service && event):
          cat = new Categories().items.find(el=> el.id == 3)          
          break;
        case (service && event):
          cat = new Categories().items.find(el=> el.id == 4)          
          break;
      }

      return new Category({
        id: 1,
        event_id: r.event_id,
        service_id: r.service_id,
        identifier: this.$t(cat.label),
        label: cat.label,
        direction: r.dir_id
      })
    }

  },
};
</script>

<style lang="scss" scoped>

.category-container{
  margin-bottom: -18px;
}
.category-container,
.category-header {
  background-color: #0f1422;
  border: solid 1px #0a0e18;
  border-radius: 5px;
}

.page-container {
  display: grid;
  overflow: hidden;
  grid-template-columns: 2.5% 95% 2.5%;
  grid-auto-rows: 1fr;

  .right-container,
  .left-container {
    border-radius: 5px 0 0 5px;
    //cursor: pointer;
    width: 100%;
    height: 90%;
    // max-height: 600px;

    position: relative;
    top: 5%;
    border: solid 1px #0a0e18;
    border-right: none;

    background: #070a13;

    transition: all 0.3s ease-in;
    .nav-icon {
      opacity: 1;
      font-size: 44px;
      position: relative;
      top: calc(50% - 22px);

      transition: all 0.1s ease-in;
    }

    .vertical-text {
      opacity: 0;
      display: block;
      position: relative;
      top: 50%;
      width: max-content;

      transform: rotate(270deg) translate(-25%, 50%);
      transform-origin: left top;

      transition: all 0.2s ease-in;
    }

    /*
    &:hover {
      .nav-icon {
        opacity: 0;
      }

      background: #0a0e1c;

      .vertical-text {
        opacity: 1;
      }
    }
    */
  }

  .category-container {
    width: 100%;
    display: flex;
  }
}
</style>