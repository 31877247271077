<template>
  <div class="d-inline-block mr-1 helper-container">

    <b-modal
      :id="uid"
      :size="size"
      lazy
      ok-only
      :title="$t('agent.modal.view.title', {'agent-type': $t(info.enum_agent_action.label)})"
      :ok-title="$t('common.terms.ok')"
    >
    <div v-if="registers">
      <p v-for="(register, key) in registers" :key="`register-info-${key}`" :id="`register-info-${key}`">
        <b-col class="my-1" v-if="register.source.id">
          <b-avatar
            :text="getRegisterAvatar(key)"
            size="24"
            variant="light-primary"
            class="mr-1"
          />
          {{ register.source.path }}
          <feather-icon
            class="mx-1 text-primary"
            size="18"
            icon="ArrowRightIcon"
          />
           {{ register.value.key }}
        </b-col>
      </p>
    </div>
    <div v-else class="mt-1">
      <b-row v-for="key in Object.keys(registerAvatarTypes).length" :key="`skeleton-${key}`">
        <b-skeleton class="mx-1" type="avatar" size="30px"/>
        <b-skeleton class="mb-2 mt-25" animation="wave" height="25px" width="200px"/>
        <b-skeleton class="mx-1"  size="30px" type="avatar"/>
        <b-skeleton md="3" class="mt-25" animation="wave" height="25px" width="200px"/>
      </b-row>
    </div>

      <b-tooltip placement="left" target="register-info-1">
          <span>{{ $t(`agent.tooltip.register_1_value`) }}</span>
      </b-tooltip>
      <b-tooltip placement="left" target="register-info-2">
        <span>{{ $t(`agent.tooltip.register_2_value`) }}</span>
      </b-tooltip>
      <b-tooltip placement="left" target="register-info-3">
        <span>{{ $t(`agent.tooltip.register_destiny_value`) }}</span>
      </b-tooltip>


      <hr>
      <p>
        <b-col>
          {{ $t('agent.modal.view.execution_order') }}: 
          <strong class="mr-2">{{ info.execution_order }} °</strong>
          {{ $t('agent.modal.view.fatal_on_fail') }}:
          <feather-icon
            size="20"
            :class="info.fatal_on_fail ? 'text-primary' : 'text-danger'"
            :icon="info.fatal_on_fail ? 'CheckIcon' : 'XIcon'"
          />
          
        </b-col>
      </p>
      <p>
        <b-col class="my-1">
          <feather-icon
            class="mr-1"
            size="17"
            icon="CalendarIcon"
          />
          {{ $t('common.terms.created_at') + ': &nbsp;' + created_at}}
          
        </b-col>
        <b-col>
          
          <feather-icon
            class="mr-1"
            size="17"
            icon="CalendarIcon"
          />
          {{ $t('common.terms.updated_at')  + ': &nbsp;' + updated_at }}
          
        </b-col>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BAvatar, BCol, BTooltip, BSkeleton, BRow } from 'bootstrap-vue'
import SourceInput from '@/custom/class/SourceInput';
import SourceValue from '@/custom/class/SourceValue';
import moment from 'moment'

export default {
  props: {
    uid:{
      type: String,
      required: true,
    },
    info: {
      type: Object,
      required: true
    },
    title: {
      type: undefined,
      default: 'View'
    },
    size: {
      type: String,
      default: 'lg'
    },
  },
  components: {
    BModal,
    BAvatar,
    BCol,
    BTooltip,
    BSkeleton,
    BRow
  },
  data() {
    return {
      registers: null,
      registerAvatarTypes: {
        1 : "R1",
        2 : "R2",
        3 : "RD",
      },
    }
  },
  mounted(){
    this.getRegisters()
  },
  computed: {
    created_at(){
      return moment(this.info.created_at).format("LLL")
    },
    updated_at(){
      return moment(this.info.updated_at).format("LLL")
    } 
  },
  methods: {
    getRegisters(){
      let map = new SourceInput({ label: "" });
      
      map.getVariables().then(() => {
        const idMap = map.idMap;

        const id_1 = this.info.register_1_source;
        const path_1 = idMap[id_1];

        const id_2 = this.info.register_2_source;
        const path_2 = idMap[id_2];

        const id_3 = this.info.destiny_source;
        const path_3 = idMap[id_3];

        const register = {
          1: new SourceValue(
            { 
              "path": path_1, 
              "id": id_1 
            }, 
            { 
              "key": this.info.register_1_value, 
              "value": "" 
            }
          ),
          2: new SourceValue(
            { 
              "path": path_2, 
              "id": id_2 
            }, 
            { 
              "key": this.info.register_2_value, 
              "value": "" 
            }
          ),
          3: new SourceValue(
            { 
              "path": path_3, 
              "id": id_3
            }, 
            { 
              "key": this.info.destiny_value, 
              "value": "" 
            }
          )
        }

        this.registers = register;
      })
    },
    getRegisterAvatar(key) {
      return this.registerAvatarTypes[key]
    }
  },
}
</script>

<style lang="scss" scoped>

// @import "@/assets/scss/variables/_variables.scss";

.helper-container {
  position: relative;
  top: -3px;
}

</style>