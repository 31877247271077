<template>
  <div class="position-static">
    <div class="attatch-to-cursor" id="attatched-element-to-cursor">
      <flux-clipboard-middleware-display :middlewares="middlewares" ></flux-clipboard-middleware-display>
    </div>
</div> 
  
</template>

<script>
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import * as StringCompressor from '@/custom/class/FunctionClasses/StringCompressor.js'
import FluxClipboardMiddlewareDisplay from '@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/FluxClipboardMiddlewareDisplay.vue';
import Drag from '@/views/pages/middleware/MiddlewareManagement/Components/Drag.vue'

import {
  BButton,
  BCard,
  BTabs,
  BTab,
  BFormInput,
  BIcon,
  BCollapse,
  BModal,
  BFormFile,
  BFormTextarea,
  BIconstack
  
} from 'bootstrap-vue'
import { makeToast } from '@/layouts/components/Popups';

export default {
  components: {
    BButton,
    BCard,
    BTabs,
    BTab,
    BFormInput,
    BIcon,
    BCollapse,
    BModal,
    VuePerfectScrollbar,
    BFormFile,
    BFormTextarea,
    FluxClipboardMiddlewareDisplay,
    Drag,
    BIconstack
  },
  props: {
    middlewares:{
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {
      trackElementToCursor: (()=>{
        let circle = document.getElementById('attatched-element-to-cursor');
        var w = window.event;

        var left = w.clientX;
        var top = w.clientY;

        circle.style.left = left + 'px';
        circle.style.top = top + 'px';    
      }),
      clearCopyMiddlewares: (()=>{
        this.$store.dispatch('userCopyMiddlewares', undefined)
        this.$store.dispatch('userClearReorderMiddlewares')
        this.$emit('deactivateCursorAttachement')  
      })
    }
  },
  computed: {
    ...mapGetters(["getSelectedMiddlewares"]),
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    document.removeEventListener('mousemove', this.trackElementToCursor)
    document.removeEventListener('click', this.clearCopyMiddlewares)    
  },
  methods: {
    init(){
      document.addEventListener('mousemove', this.trackElementToCursor);
      setTimeout(() => {
        document.addEventListener("click", this.clearCopyMiddlewares)
      }, 200);
    },
  },
}
</script>

<style>

</style>

<style lang="scss" scoped>
.attatch-to-cursor{
  position:fixed;
  transform:translate(-50%,-50%);
  z-index: 1;
  pointer-events: none !important;
  scale: 0.8;
  opacity: 0.8;
  /*
  outline: 1px solid var();
  padding: 10px 20px 20px 0px;
  border-radius: 10px;
  */

  cursor:  grabbing;
}
</style>