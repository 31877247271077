<template>
  <div>
    <b-row no-gutters>


      <b-col cols="6" class="px-50 mt-1">
        <label class="mx-25">Username</label>
        <b-input-group>
          <b-form-input trim v-model="data['user']" :disabled="isFieldDisabled('user')"/> 
          <b-input-group-append v-if="editFieldsIndividually">
              
            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('user')" v-if="!editingFields.includes('user')">
              <b-icon icon="pencil"/>
            </b-button>
            <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('user')" v-else>
              <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
            </b-button>

          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col cols="6" class="px-50 mt-1">
        <label class="mx-25">Host</label>
        <b-input-group>
          <b-form-input trim v-model="data['host']" :disabled="isFieldDisabled('host')" :state="fieldStates['host']" @input="fieldStates['host']=null"/> 

          <b-input-group-append v-if="editFieldsIndividually">

            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('host')" v-if="!editingFields.includes('host')">
              <b-icon icon="pencil"/>
            </b-button>
            <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('host')" v-else>
              <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
            </b-button>

          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col cols="6" class="px-50 mt-1">
        <label class="mx-25 font-weight-bold">Connect using:</label>
        <b-input-group>
          <b-form-select :options="auth_types" v-model="data['auth_type']" :disabled="!editingFields.includes('auth_type') && editFieldsIndividually"/>
          <b-input-group-append v-if="editFieldsIndividually">
            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('auth_type')" v-if="!editingFields.includes('auth_type')">
              <b-icon icon="pencil"/>
            </b-button>
            <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('auth_type')" v-else>
              <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col cols="6" class="px-50 mt-1" v-if="data['auth_type']=='password'">
        <label class="mx-25">Password</label>
        <b-input-group >
          <b-form-input trim v-model="data['pass']" :disabled="isFieldDisabled('pass')" :type="!editingFields.includes('pass') ? 'password' : 'text' " v-if="(editingFields.includes('pass') || !editFieldsIndividually)"/>
          <b-form-input trim v-model="mockPassword" :disabled="isFieldDisabled('pass')" v-else/>

          <b-input-group-append v-if="editFieldsIndividually">
                
            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('pass')" v-if="!editingFields.includes('pass')">
              <b-icon icon="pencil"/>
            </b-button>
            <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('pass')" v-else>
              <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
            </b-button>

          </b-input-group-append>
          <b-input-group-append v-else>
            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('pass')" v-if="!editingFields.includes('pass')">
              <b-icon icon="eye"/>
            </b-button>
            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.splice(editingFields.indexOf('pass') , 1)" v-else>
              <b-icon icon="eye-slash"/>
            </b-button>
          </b-input-group-append>
        </b-input-group> 
      </b-col>

      <b-col cols="6" v-else>
          <b-col cols="12" class="px-50 mt-1">
            <label class="mx-25">
              Certificate
            </label>
            <b-input-group>
              <b-form-textarea v-model="data['auth_certificate']" class="controlled-height" :disabled="isFieldDisabled('auth_certificate')"/> 
              <b-input-group-append v-if="editFieldsIndividually">
                  
                <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('auth_certificate')" v-if="!editingFields.includes('auth_certificate')">
                  <b-icon icon="pencil"/>
                </b-button>
                <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('auth_certificate')" v-else>
                  <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
                </b-button>

              </b-input-group-append>
            </b-input-group>
          </b-col>
      </b-col>

      <b-col cols="6" class="px-50 mt-1">
        <label class="mx-25">Port</label>
        <b-input-group class="number-input-hide-caret" >
          <b-form-input trim type="number" v-model="data['port']" :disabled="isFieldDisabled('port')" @input="data['port'] = parseInt(data['port']); fieldStates['port']=null" :state="fieldStates['port']"/> 
          <b-input-group-append v-if="editFieldsIndividually">

            <b-button class="py-0 px-50" variant="outline-dark" @click="editingFields.push('port')" v-if="!editingFields.includes('port')">
              <b-icon icon="pencil"/>
            </b-button>
            <b-button class="py-0 px-50 text-success" variant="outline-dark" @click="saveFieldChange('port')" v-else>
              <b-icon icon="check2"/> <span class="small font-weight-bolder">Save</span>
            </b-button>

          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col cols="1" class="px-1 mt-1">
        <div class="mt-0 mb-1">
          <label class="">SFTP:</label>
          <div class="d-flex mt-50">
            <b-form-checkbox class="mx-50" switch v-model="data['tls']" unchecked-value="disabled" value="true"  :disabled="isFieldDisabled('ssl')">
              <span class="switch-icon-left">
                on
              </span>
              <span class="switch-icon-right">
               off
              </span>
            </b-form-checkbox>
          </div>
        </div>
      </b-col>

      <b-col cols="1" v-if="editFieldsIndividually">
        <div class="mt-2">
          <b-button variant="none" class="text-success d-flex align-items-center" v-if="editingFields.includes('ssl')" @click="saveChangeSsl()">
            <div class="mt-1 d-flex align-items-center">
              <b-icon icon="check2"/> <span class="small font-weight-bolder ml-25">Save</span>
            </div>
          </b-button>

          <b-button variant="none" class="p-25 mt-1" v-else @click="editingFields.push('ssl')">
            <b-icon icon="pencil"/>
          </b-button>
        </div>
      </b-col>


    </b-row>
    
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import ConnectionTypes from '@/custom/class/Enum/ConnectionTypes.js'
import createConnectionModal from "@/views/pages/middleware/MiddlewareManagement/Components/Modals/CreateConnectionModal.vue"
import connectionStatus from '@/custom/class/Enum/ConnectionStatus.js'
import { makeToast } from '@/layouts/components/Popups';
import VSelect from "vue-select";

import {
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BButton,
  BTable,
  BBadge,
  BSpinner,
  BCollapse,
  BCard,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    createConnectionModal,
    BSpinner,
    BCollapse,
    BCard,
    BFormCheckbox,
    VSelect,
    BFormTextarea,
    BFormFile,
    BFormSelect,
  },
  data() {
    return {
      fieldsDictionary:{
        user: 'user',
        pass: 'pass',
        host: 'host',
        port: 'port',
        auth_type: 'auth_type',
        auth_certificate: 'auth_certificate'

      },
      fieldStates: {
        host: null,
        port: null,
      },
      defaultValues:{
        port: 21,
        auth_type: 'password',
        tls: "disabled"
      },
      requiredFields:['host', 'port'],
      editingFields: [],
      mockPassword: '* * * * * * *',
      auth_types: ['password', 'certificate'],
    }
  },
  props: {
    data:{
      type: Object,
      default: undefined 
    },
    editFieldsIndividually:{
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('connection',['getConnections']),
    connection: {
      get() {
        return this.data;
      },
      set(data) {
        this.$emit("input", data);
      },
    },
    connections(){
      return this.getConnections
    },
    organizationID() {
      return localStorage.getItem('selectedOrganization')
    },
  },
  mounted () {
    if (!this.editFieldsIndividually){
      Object.keys(this.defaultValues).forEach(key => {
        this.$set(this.data, key, this.defaultValues[key])
      });
    }  
  },
  methods: {
    getTypeByID(id){
      let type = new ConnectionTypes().items.find(el=>el.id == id)
      return type
    },
    getEnumStatus(id = undefined){
      if (id){
        let item = new connectionStatus().items.find(el=>el.id == id)
        return item
      } else {
        return new connectionStatus().items
      }
    },
    saveFieldChange(field){
      {
        let idx = this.editingFields.findIndex(el=> el == field)
        if (idx > -1){
          this.editingFields.splice(idx, 1)
        }
      }

      let convField = this.fieldsDictionary[field]

      let payload = {
        [convField] : this.data[convField] || this.data[field],
        organization: this.organizationID,
        connection: this.data.id
      }      
           
      this.$store.dispatch('connection/patchConnectionField', payload).then((resp)=>{
        makeToast({
          title: `Saved!`,
          text: `Connection successfully updated`,
          variant: 'success',
          icon: 'CheckIcon'
        })
      })
      .catch((err)=>{
        console.error(err)
        makeToast({
          title: `Error!`,
          text: `Connection couldn't be updated`,
          variant: 'danger',
          icon: 'XIcon'
        })
      })
      .finally(()=>{
      })

    },
    isFieldDisabled(field){      
      let check = this.editingFields.includes(field)
      return !check && this.editFieldsIndividually
    },
    areInputsValid(){
      let r = true
      
      this.requiredFields.forEach(field => {
        if (!this.data[field] || this.data[field].length <= 0){
          this.fieldStates[field] = false
          r = false
        }
      });

      return r
    },
    saveChangeSsl(){
      {
        let idx = this.editingFields.findIndex(el=> el == 'ssl')
        if (idx > -1){
          this.editingFields.splice(idx, 1)
        }
      }

      let payload = {
        tls : String(this.data['tls']),
        organization: this.organizationID,
        connection: this.data.id
      }
           
      this.$store.dispatch('connection/patchConnectionField', payload).then((resp)=>{
        makeToast({
          title: `Saved!`,
          text: `Connection successfully updated`,
          variant: 'success',
          icon: 'CheckIcon'
        })
      })
    },
  }
};
</script>

<style lang="scss">

.number-input-hide-caret{
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}


.controlled-height{
  max-height: 38px;
}
</style>